<template>
    <div class="rollover-popup">
        <transition name="popup">
            <div v-if="isResourcePopup" @click.self="closeRollover" class="popup">
                <div class="popup-wrapper">
                    <slot></slot>
                </div>
            </div>
        </transition>
        <transition name="vignette">
            <div v-if="isResourcePopup" class="vignette"></div>
        </transition>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: 'RolloverPopup',
        computed: {
            ...mapGetters([
                'isResourcePopup'
            ])
        },
        methods: {
            closeRollover() {
                this.$store.commit('TOGGLE_RESOURCE_POPUP', false)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables';
    @import '../../assets/scss/base';

    .vignette {
        background: rgba(#000, 0.35);
        position: fixed;
        width: 100%;
        height: 100%;
        z-index: 9;
    }

    .popup {
        overflow-y: auto;
        position: absolute;
        z-index: 20;
        display: grid;
        padding: 40px;
        height: 100%;
        bottom: 0px;
        left: 0;
        right: 0;
    }

    .popup-wrapper {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        overflow-y: auto;
        background: white;
        z-index: 12;
        position: absolute;
        width: 100%;
        height: 80%;
        bottom: 0;
    }

    .popup-enter-active {
        animation: popup-slide-in 0.35s 0.15s ease both;
    }

    .popup-leave-active {
        animation: popup-slide-in 0.15s ease reverse;
    }

    .vignette-enter-active {
        animation: vignette 0.15s 0.15s ease both;
    }

    .vignette-leave-active {
        animation: vignette 0.15s ease reverse;
    }

    @keyframes popup-slide-in {
        0% {
            transform: translateY(100%);
        }
        100% {
            transform: translateY(0);
        }
    }

    @keyframes vignette {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
</style>
