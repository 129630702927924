<template>
    <nav class="navigation">
        <div class="navigation-wrapper">
            <img src="/images/vuefilemanager-logo.svg" alt="VueFileManager Logo" class="logo">
            <div class="resources-wrapper resources">
                <div class="navigation-button resources-hover">
                    <GridIcon class="icon" size="24" />
                    <span class="title">Resources</span>
                </div>
                <ResourceLinks class="resources" :resources="resources" />
            </div>
        </div>
        <div class="navigation-wrapper">
            <a href="https://twitter.com/vuefilemanager" target="_blank" class="navigation-button text-hover twitter">
                <TwitterIcon class="icon" size="24" />
                <span class="title">Follow Us on Twitter</span>
            </a>
            <a href="https://codecanyon.net/item/vue-file-manager-with-laravel-backend/25815986" target="_blank" class="buy-button">
                <span>Get On CodeCanyon</span>
            </a>
            <div @click="toggleMenu" class="menu-icon" :class="{'is-active': isResourcePopup}">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </nav>
</template>

<script>
import { GridIcon, TwitterIcon } from 'vue-feather-icons'
import ResourceLinks from './UI/ResourceLinks.vue'
import { mapState } from 'vuex'

export default {
  name: 'TheNavigation',
  components: {
    ResourceLinks,
    TwitterIcon,
    GridIcon
  },
  computed: {
    ...mapState([
      'isResourcePopup',
      'resources'
    ])
  },
  methods: {
    toggleMenu () {
      this.$store.commit('TOGGLE_RESOURCE_POPUP', true)
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';
    @import '../assets/scss/base';

    .navigation {
        top: 0;
        background: rgba(white, 0.85);
        position: sticky;
        z-index: 9;
        margin-top: 30px;
        padding: 0 50px;
        display: flex;
        justify-content: space-between;
        backdrop-filter: blur(18px);
    }

    .navigation-wrapper {
        display: flex;
        align-items: center;
    }

    .navigation-button {
        margin: 10px;
        padding: 15px;
        font-size: 16px;
        color: $text;
        font-weight: 700;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: $border-radius;
        @include transition(200ms);
        white-space: nowrap;

        &.resources-hover:hover {
            background: $background-light;
        }

        &.text-hover:hover .title {
            color: $theme;
        }

        .title {
            @include transition(200ms);
        }

        .icon {
            margin-right: 18px;
            stroke: $theme;
        }
    }

    .logo {
        margin-right: 55px;
    }

    .buy-button {
        border-radius: $border-radius;
        background: rgba($theme, 0.1);
        padding: 15px 25px;
        margin-left: 25px;
        @include transition();
        white-space: nowrap;

        span {
            color: $theme;
            font-weight: 700;
        }

        &:hover {
            background: rgba($theme, 0.25);
            @include transform(scale(1.02));
        }
    }

    .resources-wrapper {
        position: relative;

        .resources {
            display: none;
        }

        &:hover .resources {
            display: block;
        }
    }

    .menu-icon {
        display: none;
    }

    @media only screen and (max-width: 1024px) {

        .navigation {
            margin-top: 10px;
            padding: 0 20px;
        }

        .logo {
            margin-right: 15px;
        }
    }

    @media only screen and (max-width: 960px) {

        .navigation {
            margin-top: 0;
            padding: 20px 20px;
        }

        .resources,
        .twitter,
        .buy-button {
            display: none;
        }

        .menu-icon {
            cursor: pointer;
            vertical-align: middle;
            display: inline-block;

            span {
                width: 17px;
                height: 2px;
                display: block;
                margin: 4px 0;
                background-color: #1b2539;
                transition: 0.3s all ease;
                border-radius: 3px;
            }

            &.is-active span {
                &:nth-child(1) {
                    transform: translateY(4px) rotate(45deg);
                }

                &:nth-child(2) {
                    opacity: 0;
                    transform: translateX(6px);
                }

                &:nth-child(3) {
                    transform: translateY(-8px) rotate(-45deg);
                }
            }
        }
    }
</style>
