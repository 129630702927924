<template>
    <div id="app-wrapper">
        <MobileResources />
        <div class="web" :class="{'is-scaled-down': isResourcePopup}">
            <TheNavigation />
            <TheHeader />
            <TheFooter />
        </div>
    </div>
</template>

<script>
import MobileResources from './components/UI/MobileResources.vue'
import TheNavigation from './components/TheNavigation.vue'
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    MobileResources,
    TheNavigation,
    TheFooter,
    TheHeader
  },
  computed: {
    ...mapGetters([
      'isResourcePopup'
    ])
  }
}
</script>

<style lang="scss">
    @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
    @import 'assets/scss/variables';
    @import 'assets/scss/base';

    body {
        overflow: hidden;
    }

    .web {
        overflow-x: hidden;
        overflow-y: auto;
        @include transition(150ms);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        &.is-scaled-down {
            @include transform(scale(0.95));
        }
    }
</style>
