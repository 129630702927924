<template>
    <RolloverPopup>
        <ResourceLinks class="resources" :resources="resources" />
    </RolloverPopup>
</template>

<script>
import ResourceLinks from './ResourceLinks.vue'
import RolloverPopup from './RolloverPopup.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'MobileResources',
  components: {
    ResourceLinks,
    RolloverPopup
  },
  computed: {
    ...mapGetters([
      'resources'
    ])
  }
}
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables';
    @import '../../assets/scss/base';

    .resources {
        box-shadow: none;
        width: 100%;
        left: 0;
        top: 0;

        .resource-item {
            margin: 10px 0;
        }
    }
</style>
