<template>
    <header class="page-header">
        <div class="headline">
            <h1 class="title">
                Your <span>Self-Hosted</span><br> Storage Client Platform<span>.</span>
            </h1>
            <h2 class="description">
                VueFileManager is <span>self-hosted client</span> for managing your files on your cloud. Upload and share your files with your friends & clients.
            </h2>
            <a href="https://demo-metered.vuefilemanager.com/sign-in" target="_blank" class="demo-button">
                Try Demo
            </a>
        </div>
        <div class="wrapper-hero">
            <img src="/images/hero.jpg" alt="VueFileManager design">
        </div>
        <div class="technology-stack">
            <p class="technology-description">
                Made with the most popular Front-End & Back-End Frameworks:
            </p>
            <div class="logo-wrapper">
                <a href="https://vuejs.org/" target="_blank">
                    <img src="/images/vuejs-logo.svg" alt="Vue.js Framework">
                </a>
                <span class="delimiter">&</span>
                <a href="https://laravel.com/" target="_blank">
                    <img src="/images/laravel-logo.svg" alt="Laravel PHP Framework">
                </a>
            </div>
        </div>
        <div class="icons">

            <!--Right side-->
            <SettingsIcon size="22" class="icon" />
            <HardDriveIcon size="34" class="icon" />
            <ImageIcon size="32" class="icon" />
            <FolderPlusIcon size="32" class="icon" />
            <SearchIcon size="18" class="icon" />
            <Trash2Icon size="27" class="icon" />
            <StarIcon size="26" class="icon" />
            <FolderPlusIcon size="19" class="icon" />

            <!--Left side-->
            <SearchIcon size="22" class="icon" />
            <LinkIcon size="19" class="icon" />
            <FolderPlusIcon size="36" class="icon" />
            <StarIcon size="24" class="icon" />
            <StarIcon size="16" class="icon" />
            <HardDriveIcon size="34" class="icon" />
            <EyeIcon size="26" class="icon" />
            <SettingsIcon size="19" class="icon" />
        </div>
    </header>
</template>

<script>
import {
  FolderPlusIcon,
  HardDriveIcon,
  SettingsIcon,
  SearchIcon,
  Trash2Icon,
  ImageIcon,
  LinkIcon,
  StarIcon,
  EyeIcon
} from 'vue-feather-icons'

export default {
  name: 'TheHeader',
  components: {
    FolderPlusIcon,
    HardDriveIcon,
    SettingsIcon,
    SearchIcon,
    Trash2Icon,
    ImageIcon,
    LinkIcon,
    StarIcon,
    EyeIcon
  }
}
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';
    @import '../assets/scss/base';

    .page-header {
        position: relative;
    }

    .headline {
        text-align: center;
        max-width: 800px;
        margin: 55px auto 0;

        .title {
            font-size: 68px;
            font-weight: 800;
            letter-spacing: -1.4px;
            line-height: 1;

            span {
                font-size: inherit;
                font-weight: 800;
                color: $theme;
            }
        }

        .description {
            font-size: 20px;
            font-weight: 600;
            margin-top: 22px;
            line-height: 1.6;

            span {
                font-size: 20px;
                font-weight: 700;
            }
        }
    }

    .demo-button {
        position: relative;
        z-index: 2;
        display: inline-block;
        margin-top: 24px;
        font-size: 18px;
        font-weight: 700;
        color: white;
        padding: 10px 28px;
        border-radius: 50px;
        background: rgb(74, 209, 149);
        background: linear-gradient(45deg, rgba(74, 209, 149, 1) 0%, rgba(65, 184, 131, 1) 100%);
        box-shadow: 0 5px 15px rgba(#41B883, 0.5);
        @include transition();
        white-space: nowrap;

        &:hover {
            @include transform(scale(1.03));
        }

        &:active {
            @include transform(scale(0.97));
        }
    }

    .wrapper-hero {
        text-align: center;
        position: relative;
        z-index: 1;

        img {
            width: 100%;
            padding-right: 220px;
            padding-left: 220px;
        }
    }

    .technology-stack {
        margin: -40px auto 0;
        width: 370px;
        text-align: center;
        position: relative;
        z-index: 2;

        .logo-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;

            .delimiter {
                font-size: 20px;
                margin: 0 20px 0 10px;
            }
        }

        .technology-description {
            font-size: 16px;
            font-weight: 600;
            max-width: 270px;
            margin: 0px auto 20px;
        }
    }

    .icons {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        top: 0;

        .icon {
            position: absolute;

            &:nth-child(1) {
                top: 9%;
                right: 23%;
                stroke: $red;
            }

            &:nth-child(2) {
                top: 16%;
                right: 25.5%;
                stroke: $green;
                transform: rotate(-15deg) scale(1);
            }

            &:nth-child(3) {
                top: 17%;
                right: 15%;
                transform: rotate(18deg) scale(1);
            }

            &:nth-child(4) {
                top: 14.6%;
                right: 9.5%;
                stroke: $yellow;
                transform: rotate(15deg) scale(1);
            }

            &:nth-child(5) {
                top: 30%;
                right: 12%;
                stroke: $purple;
            }

            &:nth-child(6) {
                top: 34%;
                right: 7%;
                stroke: $red;
                transform: rotate(22deg) scale(1);
            }

            &:nth-child(7) {
                top: 46%;
                right: 6%;
                stroke: $yellow;
            }

            &:nth-child(8) {
                top: 55.5%;
                right: 9%;
                transform: rotate(-15deg) scale(1);
            }

            &:nth-child(9) {
                top: 6%;
                left: 18.5%;
                stroke: $purple;
            }

            &:nth-child(10) {
                top: 15%;
                left: 8.5%;
            }

            &:nth-child(11) {
                top: 15%;
                left: 21%;
                stroke: $yellow;
                transform: rotate(15deg) scale(1);
            }

            &:nth-child(12) {
                top: 20.5%;
                left: 13%;
                stroke: $red;
            }

            &:nth-child(13) {
                top: 22%;
                left: 3.5%;
                stroke: $purple;
            }

            &:nth-child(14) {
                top: 29%;
                left: 9%;
                stroke: $green;
                transform: rotate(-14deg) scale(1);
            }

            &:nth-child(15) {
                top: 36%;
                left: 14%;
                transform: rotate(23deg) scale(1);
            }

            &:nth-child(16) {
                top: 40%;
                left: 8%;
                stroke: $red;
            }
        }

    }

    @media only screen and (max-width: 1680px) {
        .wrapper-hero {
            width: 100%;

            img {
                padding-left: 60px;
                padding-right: 60px;
            }
        }

        .icons {

            .icon {

                &:nth-child(1) {
                    right: 15%;
                }

                &:nth-child(2) {
                    top: 19%;
                    right: 22.5%;
                }

                &:nth-child(3) {
                    right: 12%;
                }

                &:nth-child(4) {
                    right: 5.5%;
                }

                &:nth-child(5) {
                    right: 9%;
                }

                &:nth-child(6) {
                    right: 4%;
                }

                &:nth-child(7) {
                    right: 2%;
                }

                &:nth-child(8) {
                    right: 11%;
                }

                &:nth-child(9) {
                    left: 13.5%;
                }

                &:nth-child(10) {
                    left: 4.5%;
                }

                &:nth-child(11) {
                    left: 17%;
                }

                &:nth-child(12) {
                    left: 11%;
                }

                &:nth-child(13) {
                    left: 1.5%;
                }

                &:nth-child(14) {
                    left: 5%;
                }

                &:nth-child(15) {
                    left: 2%;
                }

                &:nth-child(16) {
                    left: 6%;
                }
            }
        }
    }

    @media only screen and (max-width: 1280px) {

        .headline {

            .title {
                font-size: 58px;
                line-height: 1;
            }

            .description {
                font-size: 18px;
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;

                span {
                    font-size: 18px;
                }
            }
        }
    }

    @media only screen and (max-width: 1024px) {

        .page-header {
            padding-left: 20px;
            padding-right: 20px;
        }

        .headline {
            margin: 45px auto 10px;
        }

        .wrapper-hero img {
            padding-left: 20px;
            padding-right: 20px;
        }

        .icons .icon {

            &:nth-child(1) {
                right: 12%;
                top: 2%;
            }

            &:nth-child(2) {
                top: 22%;
                right: 18.5%;
            }

            &:nth-child(3) {
                right: 12%;
                top: 15%;
            }

            &:nth-child(4) {
                right: 4.5%;
                top: 9.6%;
            }

            &:nth-child(5) {
                right: 7%;
                top: 26%;
            }

            &:nth-child(9) {
                left: 12.5%;
                top: 3%;
            }

            &:nth-child(10) {
                left: 4.5%;
                top: 12%;
            }

            &:nth-child(11) {
                left: 13%;
                top: 14%;
            }

            &:nth-child(12) {
                left: 18%;
                top: 22.5%;
            }

            &:nth-child(12) {
                left: 7.5%;
                top: 25.5%;
            }

            &:nth-child(16) {
                left: 4%;
            }

            &:nth-child(8),
            &:nth-child(14),
            &:nth-child(15) {
                display: none;
            }
        }
    }

    @media only screen and (max-width: 960px) {

        .wrapper-hero img {
            padding-left: 0px;
            padding-right: 0px;
        }

        .icons {
            display: none;
        }
    }

    @media only screen and (max-width: 690px) {

        .headline {
            margin: 45px auto 45px;

            .title {
                font-size: 42px;
                line-height: 1.1;
            }

            .description {
                font-size: 18px;
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;

                span {
                    font-size: 18px;
                }
            }
        }

        .technology-stack {
            margin: 0 auto 0;
            width: 100%;

            .logo-wrapper {

                a {

                    &:nth-child(1) img {
                        height: 50px;
                    }

                    &:nth-child(3) img {
                        height: 40px;
                    }
                }
            }
        }
    }

</style>
