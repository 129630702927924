<template>
    <div class="resources">
        <a :href="resource.href" target="_blank" class="resource-item" v-for="(resource, i) in resources" :key="i" :class="resource.color">
            <FileTextIcon v-if="resource.icon === 'FileTextIcon'" class="icon" size="30" />
            <Edit2Icon v-if="resource.icon === 'Edit2Icon'" class="icon" size="30" />
            <TwitterIcon v-if="resource.icon === 'TwitterIcon'" class="icon" size="30" />
            <ListIcon v-if="resource.icon === 'ListIcon'" class="icon" size="30" />
            <CreditCardIcon v-if="resource.icon === 'CreditCardIcon'" class="icon" size="30" />
            <InfoIcon v-if="resource.icon === 'InfoIcon'" class="icon" size="30" />
            <ExternalLinkIcon v-if="resource.icon === 'ExternalLinkIcon'" class="icon" size="30" />

            <div class="resource-name">
                <span class="resource-title">{{ resource.title }}<span class="dot">.</span></span>
                <small class="resource-description">{{ resource.description }}</small>
            </div>
        </a>
    </div>
</template>

<script>
import { FileTextIcon, Edit2Icon, TwitterIcon, ListIcon, CreditCardIcon, InfoIcon, ExternalLinkIcon } from 'vue-feather-icons'

export default {
  name: 'ResourceLinks',
  props: [
    'resources'
  ],
  components: {
    ExternalLinkIcon,
    CreditCardIcon,
    FileTextIcon,
    TwitterIcon,
    Edit2Icon,
    InfoIcon,
    ListIcon
  },
  data () {
    return {
      iconSize: 36
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '../../assets/scss/variables';
    @import '../../assets/scss/base';

    .resources {
        position: absolute;
        left: 10px;
        top: 60px;
        background: white;
        box-shadow: 0 22px 44px -9px rgba(#19363C, 0.14);
        padding: 13px;

        .resource-item {
            display: flex;
            align-items: center;
            padding: 16px 13px;
            border-radius: $border-radius;
            @include transition();

            .resource-name {
                line-height: 1;
                margin-left: 13px;

                .resource-title, .dot {
                    font-size: 19px;
                    font-weight: 800;
                    white-space: nowrap;
                }

                .resource-description {
                    margin-top: 5px;
                    display: block;
                    font-weight: 600;
                    color: $text-light;
                    font-size: 12px;
                    white-space: nowrap;
                }
            }

            &.yellow {
                &:hover {
                    background: rgba($yellow, 0.1);
                }

                .icon {stroke: $yellow}
                .dot {color: $yellow}
            }

            &.burgundy {
                &:hover {
                    background: rgba($burgundy, 0.1);
                }

                .icon {stroke: $burgundy}
                .dot {color: $burgundy}
            }

            &.blue {
                &:hover {
                    background: rgba($blue, 0.1);
                }

                .icon {stroke: $blue}
                .dot {color: $blue}
            }

            &.purple {
                &:hover {
                    background: rgba($purple, 0.1);
                }

                .icon {stroke: $purple}
                .dot {color: $purple}
            }

            &.green {
                &:hover {
                    background: rgba($green, 0.1);
                }

                .icon {stroke: $green}
                .dot {color: $green}
            }

            &.orange {
                &:hover {
                    background: rgba($orange, 0.1);
                }

                .icon {stroke: $orange}
                .dot {color: $orange}
            }

            &.red {
                &:hover {
                    background: rgba($red, 0.1);
                }

                .icon {stroke: $red}
                .dot {color: $red}
            }
        }
    }
</style>
