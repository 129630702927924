import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isResourcePopup: false,
    resources: [
      {
        href: 'https://gist.github.com/MakingCG/a702a112be63bc6f0032dd55522327cf',
        icon: 'FileTextIcon',
        title: 'Documentation',
        description: 'Guide to find help with VueFileManager.',
        color: 'yellow'
      },
      {
        href: 'https://medium.com/vuefilemanager',
        icon: 'Edit2Icon',
        title: 'Blog',
        description: 'Get some news about VueFileManager.',
        color: 'burgundy'
      },
      {
        href: 'https://twitter.com/vuefilemanager',
        icon: 'TwitterIcon',
        title: 'Twitter',
        description: 'Stay in touch with every news.',
        color: 'blue'
      },
      {
        href: 'https://gist.github.com/MakingCG/9c07f8af392081ae5d5290d920a79b5d',
        icon: 'ListIcon',
        title: 'Changelog',
        description: 'Find the latest changes.',
        color: 'purple'
      },
      {
        href: 'https://codecanyon.net/item/vue-file-manager-with-laravel-backend/25815986',
        icon: 'CreditCardIcon',
        title: 'CodeCanyon',
        description: 'Get your license today.',
        color: 'green'
      },
      {
        href: 'https://codecanyon.net/item/vue-file-manager-with-laravel-backend/25815986/support',
        icon: 'InfoIcon',
        title: 'Support',
        description: 'Get touch with us for help.',
        color: 'orange'
      },
      {
        href: 'https://demo-metered.vuefilemanager.com/sign-in',
        icon: 'ExternalLinkIcon',
        title: 'Demo - Metered',
        description: 'Demo for metered billing SaaS version.',
        color: 'purple'
      },
      {
        href: 'https://demo-fixed.vuefilemanager.com/sign-in',
        icon: 'ExternalLinkIcon',
        title: 'Demo - Fixed',
        description: 'Demo for fixed billing SaaS version.',
        color: 'orange'
      },
      {
        href: 'https://demo-regular.vuefilemanager.com/sign-in',
        icon: 'ExternalLinkIcon',
        title: 'Demo - Regular',
        description: 'Demo without SaaS module.',
        color: 'red'
      }
    ]
  },
  mutations: {
    TOGGLE_RESOURCE_POPUP (state, visibility) {
      state.isResourcePopup = visibility
    }
  },
  getters: {
    isResourcePopup: state => state.isResourcePopup,
    resources: state => state.resources
  }
})
