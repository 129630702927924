<template>
    <footer class="page-footer">
        <img src="/images/vuefilemanager-logo.svg" alt="VueFileManager Logo" class="logo">
        <p class="copyright">
            VueFileManager 2020. Designed and Developed by <a href="https://hi5ve.digital" target="_blank">Hi5ve.Digital</a>
        </p>

        <img src="/images/footer-bg.svg" class="background">
    </footer>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>

<style lang="scss" scoped>
    @import '../assets/scss/variables';
    @import '../assets/scss/base';

    .page-footer {
        background: $background-light;
        text-align: center;
        position: relative;
        padding: 0 0 30px;
        margin-top: 190px;

        .background {
            @include transform(translateY(-60%));
            position: absolute;
            width: 100%;
            z-index: 0;
            right: 0;
            left: 0;
            top: 0;
        }
    }

    .logo {
        position: relative;
        width: 185px;
        z-index: 2;
    }

    .copyright {
        position: relative;
        font-weight: 600;
        margin-top: 10px;
        font-size: 16px;
        z-index: 2;

        a {
            font-weight: 700;
            color: $theme;
        }
    }

    @media only screen and (max-width: 1024px) {

        .page-footer {
            margin-top: 150px;
        }

        .copyright {
            margin-top: 5px;
        }
    }

    @media only screen and (max-width: 960px) {
        .page-footer {
            padding-left: 20px;
            padding-right: 20px;

            .background {
                transform: translateY(-90%) scale(2.5);
            }
        }

        .copyright {
            line-height: 1.6;
            margin-top: 10px;
        }
    }

</style>
